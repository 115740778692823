import React, {useState} from 'react';
import {checkElements} from 'lib/generic';
import './styles/TextAreaHandleChange.css';

const TextAreaHandleChange = (props) => {
    const [input, setInput] = useState({
        input: ''
    });

    const txtareaHandleChange = (field) => {
        setInput(field.target.value);
        checkElements(field);
    };

    return (
        <>
            <div className="section__selectors--group">
                <label>{props.labelValue}</label>
                <textarea
                    value={input.inputValue}
                    id={props.inputValue}
                    name={props.inputValue}
                    onChange={txtareaHandleChange}
                    cols={props.colsValue}
                    rows={props.rowsValue}
                    className="section__selectors--textarea"
                />
            </div>
        </>
    )
}

export default TextAreaHandleChange