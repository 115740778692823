import React from 'react';
import Cookies from 'pages/cookies';
import Legal from 'pages/legal';
import Condiciones from 'pages/condiciones';
import CardsHome from 'components/cards/CardsHome';
import {Route, Routes} from 'react-router-dom';

const BodyComponent = () => {
  return (
    <>
    <main className="mt-2 lg:m-0 w-screen h-screen flex">
          <Routes>
              <Route path="/" element={<CardsHome/>} />
              <Route path="/legal" element={<Legal/>}></Route>
              <Route path="/condiciones" element={<Condiciones/>}></Route>
              <Route path="/cookies" element={<Cookies/>}></Route>
          </Routes>
    </main>
    </>
)
}

export default BodyComponent;