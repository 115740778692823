import React from 'react';
import './styles/CopyToClipboard.css';

const CopyToClipboard = () => {
    const CopyHtmlToClipboard = () => {
        const divElement = document.getElementById("texttool-content");

        var tempContainer = document.createElement("div");
        tempContainer.appendChild(divElement.cloneNode(true));

        tempContainer.querySelectorAll('.display-none').forEach(function(element) {
            element.parentNode.removeChild(element);
        });

        var divHTML = tempContainer.innerHTML;

        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(divHTML).then(function() {
            }, function(err) {
                alert("Failed to copy text: ", err);
            });
        } else {
            var tempTextarea = document.createElement("textarea");
            tempTextarea.value = divHTML;
            document.body.appendChild(tempTextarea);
            tempTextarea.select();
            try {
                document.execCommand('copy');
            } catch (err) {
                alert("Failed to copy text: ", err);
            }
            document.body.removeChild(tempTextarea);
        }
    };

    return (
    <>
    <button className="copyToClipboard" onClick={CopyHtmlToClipboard}>Copiar al portapapeles</button>
    </>
    )
}

export default CopyToClipboard;