import React from 'react';
import Navbar from 'components/navbar';

const HeaderComponent = () => {
  return (
    <>
    <Navbar />
    </>
)
}

export default HeaderComponent;