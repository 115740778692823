import React  from 'react';
import CopyToClipboard from 'components/button/CopyToClipboard';
import TextHandleChange from 'components/input/TextHandleChange';
import TextAreaHandleChange from 'components/input/TextAreaHandleChange';
import CheckBoxHandleChange from 'components/checkbox/checkBoxHandleChange';

const Condiciones = () => {
return (
    <>  
        <div className="flex_content">        
            <div className="legaltext_content">
                <p className="component__title">Condiciones de uso</p>
                <div className="component__description" role="alert">
                    <p className="font-bold">Por favor, introduzca los datos necesarios.</p>
                    <p className="text-sm">Aquello que añada en los campos de texto, se cambiará en el texto de abajo, para que pueda comprobar el resultado final.</p>
                </div>
                <div className="section__selectors">
                    <TextHandleChange inputValue={"nombreEmpresa"} labelValue={"Nombre Empresa"} />
                    <TextHandleChange inputValue={"domicilioSocial"} labelValue={"Domicilio Social"} />
                    <TextHandleChange inputValue={"paginaWeb"} labelValue={"Página Web"} />
                    <TextHandleChange inputValue={"correoElectronico"} labelValue={"Correo Electrónico"} />
                    <TextHandleChange inputValue={"ivaAnyadido"} labelValue={"IVA Añadido (%)"} />
                </div>
                <hr />
                <div className="section__selectors">
                    <TextAreaHandleChange
                        rowsValue={"10"}
                        colsValue={"30"}
                        inputValue={"gastosEnvio"}
                        labelValue={"Gastos de Envío"} />
                    <TextAreaHandleChange
                        rowsValue={"10"}
                        colsValue={"30"}
                        inputValue={"portesGratuitos"}
                        labelValue={"Condiciones para Portes Gratuitos"} />
                    <TextAreaHandleChange
                        rowsValue={"10"}
                        colsValue={"30"}
                        inputValue={"plazosEntrega"}
                        labelValue={"Plazos de Entrega"} />
                    <TextAreaHandleChange
                        rowsValue={"10"}
                        colsValue={"30"}
                        inputValue={"devoluciones"}
                        labelValue={"Devoluciones"} />
                </div>
                <hr />
                <h2>Formas de pago</h2>
                <div>
                    <CheckBoxHandleChange
                        labelValue={"Tarjeta de Crédito/Débito (VISA, Mastercard, American Express, Affinity Card)"}
                        checkBoxKey={"formaspago"}
                        checkBoxProp={"tarjeta"}
                        inputValue={"Tarjeta de Crédito/Débito (VISA, Mastercard, American Express, Affinity Card)"}
                    />
                    <CheckBoxHandleChange
                        labelValue={"Paypal"}
                        checkBoxKey={"formaspago"}
                        checkBoxProp={"paypal"}
                        inputValue={"Paypal"}
                    />
                    <CheckBoxHandleChange
                        labelValue={"Contrarembolso"}
                        checkBoxKey={"formaspago"}
                        checkBoxProp={"contrarembolso"}
                        inputValue={"Contrarembolso"}
                    />
                    <CheckBoxHandleChange
                        labelValue={"Transferencia bancaria"}
                        checkBoxKey={"formaspago"}
                        checkBoxProp={"transferencia"}
                        inputValue={"Transferencia bancaria"}
                    />
                </div>
                <CopyToClipboard/>
            </div>
            <div id="texttool-content">
                <h2>CONDICIONES DE VENTA</h2>
                <p>Todas las compras que usted realice en <span className="paginaWeb">[[pagina-web]]</span>, están sujetas a las condiciones expuestas
                a continuación:</p>
                <ol className="group-list">
                    <li>
                        <strong>Precios:</strong> Todos los precios expuestos en nuestra tienda online <span className="paginaWeb">[[pagina-web]]</span>
                        tienen incluido el Impuesto sobre el Valor añadido vigente del <span className="ivaAnyadido">[[iva]]</span> IVA.
                    </li>
                    <li>
                        <strong>Portes:</strong> Tus portes te resultarán gratuitos bajo estas condiciones:
                        <div className="portesGratuitos">[[portes-gratuitos]]</div>
                        En el caso de que tu pedido no supere estos importes mencionados, nuestra página Web
                        te calculara el importe que deberás pagar de portes y te lo añadirá a tu factura,
                        de esta forma al realizar el pedido ya sabrás el total que has de abonar.
                    </li>
                    <li>
                        <strong>Plazos de entrega:</strong>
                        <div className="plazosEntrega">[[plazos-entrega]]</div>
                    </li>
                    <li>
                        <strong>Formas de pago:</strong> Para su comodidad, hemos dispuesto de varias formas de pago:
                        <div id="tarjeta" className="display-none">
                            <strong>Tarjetas de crédito:</strong>
                            Una vez hecha su compra, si usted selecciona la opción de pago con tarjeta, accederá
                            directamente a la intranet de su Banco o Caja, el cual como usted ya conoce es un acceso
                            totalmente seguro y fiable para usted y garantizado totalmente por su entidad. En
                            todo momento sus datos son secretos e intransferibles para nosotros.
                            Es importante que los datos solicitados coincidan con los de su tarjeta,
                            cualquier error tipográfico, el sobrepasar el límite de crédito o la caducidad de
                            la tarjeta, dará como resultado el rechazo de la operación de compra que usted haya
                            realizado.
                            Puede usted consultar con su Banco o Caja sobre cualquier duda sobre la privacidad de
                            su tarjeta como medio de pago.
                        </div>
                        <div id="paypal" className="display-none">
                            <strong>PayPal: </strong>
                            Pagarás de una forma rápida y segura desde tu cuenta de PayPal.
                        </div>
                        <div id="contrarembolso" className="display-none">
                            <strong>Contrarembolso</strong>
                        </div>
                        <div id="transferencia" className="display-none">
                            <strong>Transferencia Bancaria</strong>
                        </div>
                    </li>
                </ol>
                <h3>Facturas a nombre de su empresa:</h3>
                <p>Al llenar su carrito de compra, deberá indicar si la compra es a nivel particular, o de empresa, y en el caso de que la compra sea para su empresa, se le pedirán los datos fiscales de la misma.</p>
                <h4>1. Objetivo</h4>
                <p>Las condiciones de venta actuales (de aquí en adelante, “Condiciones de venta”) regulan el acuerdo entre la empresa <span className="nombreEmpresa">[[nombre_empresa]]</span> con domicilio en <span className="domicilioSocial">[[domicilio_social]]</span>, debidamente registrada con el Registro Mercantil y el cliente (a partir de ahora nombrado "Cliente") que formalizaron a través del sitio web <span className="paginaWeb">[[pagina-web]]</span> (a partir de ahora nombrado “Sitio web”) para la adquisición de productos.
                </p>
                <h4>2. Procedimiento de contratación</h4>
                <h5>2.1. Elegibilidad para comprar</h5>
                <p>Para poder hacer un pedido a través del Sitio web, el Cliente debe ser mayor de edad (más de 18 años) y debe ser el cliente final.
                    Los menores de edad quedan excluidos de forma expresa.</p>
                <p>Si el Cliente no está registrado, el Cliente se registrará automáticamente como tal en el sistema de <span className="paginaWeb">[[pagina-web]]</span>.</p>
                <h5>2.2. Cómo hacer un pedido</h5>
                <p>Para hacer un pedido a través del Sitio web debe seguir estos pasos:</p>
                <ul className="space-y-3 text-black list-disc marker:text-red-500 py-2 px-10">
                    <li>1. Elija los productos que desee utilizando nuestros
                    filtros y añádelos a “La cesta” de la compra.</li>
                    <li>2. Pase por caja.</li>
                    <li>3. Introduzca la información de entrega y pago.</li>
                    <li>4. Elija la forma de pago y haga clic en “Enviar pedido”.
                        Al pulsar el botón “Enviar pedido” en el formulario de pedido, el Cliente acepta totalmente
                        y sin reservas todas las Condiciones de venta que aparecen en el Sitio web en el momento de
                        la contratación.</li>
                    <li>5. Realice el pago seleccionando una de las opciones permitidas
                        en este documento.</li>
                    <li>6. Si el pago está autorizado, el cliente recibirá una confirmación
                        de recepción del pedido en la dirección de email especificada en nuestro formulario.
                        Excepto en las circunstancias descritas en los Apartados 2.3 y 2.4 más adelante, esta
                        confirmación constituye la aceptación por parte de <span className="nombreEmpresa">[[nombre_empresa]]</span>
                        del pedido del Cliente y supone un contrato vinculante entre el Cliente y
                        <span className="nombreEmpresa">[[nombre_empresa]]</span>.</li>
                </ul>
                <p>Es recomendable que el Cliente imprima y/o guarde una copia de las Condiciones de venta al realizar el pedido.</p>
                <p>Se enviará una copia de la confirmación de recepción a la dirección electrónica del Cliente. Le recomendamos que imprima o descargue una copia para posibles futuras consultas.</p>
                <p><span className="nombreEmpresa">[[nombre_empresa]]</span> puede cambiar las Condiciones de venta en cualquier momento, sin aviso previo al Cliente. Si un pedido se cancela por alguna razon, <span className="nombreEmpresa">[[nombre_empresa]]</span> reembolsará (si corresponde) el valor del pedido. Los gastos de envío nunca se reembolsan, excepto en ciertas circunstancias o cambios en el Sitio web. Además, cuando el Cliente haya recibido una confirmación de recepción de <span className="nombreEmpresa">[[nombre_empresa]]</span> de su pedido, las Condiciones de venta aplicables al mismo no se pueden cambiar.</p>
                <h5>2.3. Disponibilidad de productos</h5>
                <p>La culminación de un pedido a través del Sitio web está sujeta a la disponibilidad de las prendas. <span className="nombreEmpresa">[[nombre_empresa]]</span> intenta garantizar el stock de todas las prendas que están en venta.</p>
                <p>Si el producto pedido no está disponible o si se ha agotado su stock, se informará al Cliente por correo electrónico y se le reembolsará
                    el importe pagado antes de que transcurran los 30 días siguientes al pago.</p>
                <h5>2.4. Derecho de <span className="nombreEmpresa">[[nombre_empresa]]</span> de cancelar o de no aceptar un pedido</h5>
                <p><span className="nombreEmpresa">[[nombre_empresa]]</span> se reserva el
                    derecho de rechazar cualquier pedido, cancelarlo o no aceptar un pedido confirmado por, entre otras, las
                    siguientes razones:</p>
                <ul className="space-y-3 text-black list-disc marker:text-red-500 py-2 px-10">
                    <li>Se produjo un error técnico o de precios en el Sitio web cuando se realizó el pedido.</li>
                    <li>El producto que figura en el pedido no está disponible.</li>
                    <li>La información de pago facilitada era incorrecta o no verificable.</li>
                    <li>Los sistemas de seguridad indican que el pedido es anómalo o puede ser fraudulento.</li>
                    <li>Hay razones que indican que el Cliente es menor de edad (menos de 18 años).</li>
                    <li><span className="nombreEmpresa">[[nombre_empresa]]</span> no ha podido, tras 3 intentos, entregar el pedido a la dirección facilitada.</li>
                    <li>Si el pago es por transferencia bancaria y <span className="nombreEmpresa">[[nombre_empresa]]</span> no ha recibido confirmación del pago.</li>
                    <li>Si un pedido se cancela por alguna de las razones anteriores, <span className="nombreEmpresa">[[nombre_empresa]]</span> reembolsará (si corresponde) el valor del pedido. Los gastos de envío nunca se reembolsan, excepto en ciertas circunstancias.</li>
                </ul>
                <h5>2.5. Verificación de datos</h5>
                <p>Antes de procesar un pedido realizado por el Cliente, <span className="nombreEmpresa">[[nombre_empresa]]</span> puede verificar los datos del mismo. Esto puede significar la verificación de la dirección y la solvencia del Cliente y si puede haber algún fraude implicado. Respecto al posible fraude, <span className="nombreEmpresa">[[nombre_empresa]]</span> puede utilizar una verificación parcialmente automática de todas las compras para filtrar las que se consideren anómalas o sospechosas y las sospechosas de fraude. Los pedidos sospechosos de fraude se investigarán, y puede que se denuncie al Cliente a las autoridades pertinentes, que podrían emprender acciones contra el mismo.</p>
                <h4>3. Precio y pago</h4>
                <h5>3.1. Precios y moneda</h5>
                <p>Los precios y las ofertas que se presentan en el Sitio web incluyen IVA (Impuesto sobre el valor añadido) y sólo son válidos para los pedidos realizados en línea a través del Sitio web. Estos precios y ofertas podrían no coincidir con las disponibles en las tiendas <span className="nombreEmpresa">[[nombre_empresa]]</span> de todo el mundo.</p>
                <p>Los precios son en Euros.</p>
                <h5>3.2. Forma de pago</h5>
                <p>Para pagar el pedido el Cliente puede utilizar las formas de pago antes expuestas. El cargo se realizará tras la confirmación del pedido.</p>
                <h5>3.3. Cambios en el precio</h5>
                <p>Los precios que aparecen en el Sitio web están sujetos a cambios. <span className="nombreEmpresa">[[nombre_empresa]]</span> se reserva el derecho de cambiar los precios sin aviso previo. Sin embargo, tras la recepción de la confirmación del pedido de <span className="nombreEmpresa">[[nombre_empresa]]</span>, los precios a pagar por dicho pedido no se cambiarán.</p>
                <p><span className="nombreEmpresa">[[nombre_empresa]]</span> le informa que a pesar de las actualizaciones meticulosas que se realizan a los precios del Sitio web, podrían contener errores. Todos los errores que aparezcan en los precios se corregirán con prontitud y no serán vinculantes en <span className="nombreEmpresa">[[nombre_empresa]]</span>.</p>
                <h5>3.4. Impuesto sobre el valor añadido</h5>
                <p>Para cumplir con el IVA aplicable según las normas sobre la venta a distancia, se considerará que las entregas se realizan en el Estado miembro que aparezca en la dirección de entrega.</p>
                <p>De esta forma, todas las compras realizadas a través del Sitio web estarán sujetas al IVA. El IVA se aplicará en el porcentaje legal vigente en el Estado miembro donde se entregará la mercancía de un pedido concreto.</p>
                <p>Clientes de fuera de los territorios donde se aplica el IVA: según la política del IVA de <span className="nombreEmpresa">[[nombre_empresa]]</span>, se cobrará el IVA a los clientes de dicha zona de compra que realicen la compra a través del Sitio web al realizar el pedido.</p>
                <h4>4. Entrega</h4>
                <h5>4.1. Entrega: Dónde y cuando</h5>
                <p>Las entregas se realizan de lunes a viernes dentro del territorio nacional y según los horarios laborables estipulados de reparto en cada país europeo. No se realizan entregas en los fines de semana ni durante las fiestas locales.</p>
                <p>Las entregas se realizan en horario de oficina. Por eso, para facilitar las entregas, se pide a los Clientes que utilicen sus direcciones de trabajo u otras direcciones donde haya alguien que pueda recibir el paquete.</p>
                <h5>4.2. Plazo de entrega</h5>
                <div className="plazosEntrega">[[plazos-entrega]]</div>
                <p>Normalmente se cumple con el plazo de entrega y, aunque <span className="nombreEmpresa">[[nombre_empresa]]</span> no puede garantizar la puntualidad. Una vez enviado el pedido, el Cliente recibe un correo electrónico de notificación con la fecha de entrega estimada.</p>
                <h5>4.3. Verificación a la recepción</h5>
                <p>El Cliente debe verificar el estado del paquete al recibirlo. Si el embalaje está dañado, el Cliente no debe aceptar la entrega. El importe de la compra y los gastos de envío se reembolsarán al Cliente cuando el almacén haya procesado y devuelto los productos. <span className="nombreEmpresa">[[nombre_empresa]]</span> informa de que la entrega se debe verificar en un período de tiempo razonable tras su recepción. En caso de que no se haya recibido ningún rechazo de los productos a los 30 días de la entrega, <span className="nombreEmpresa">[[nombre_empresa]]</span> considerará que el Cliente ha aceptado la misma. Esto no afecta al derecho del Cliente de devolución o cambio de un pedido como se establece en el Apartado 6 más adelante.</p>
                <h4>5. Gastos de envío</h4>
                <p>Los gastos de envío se aplican a cada pedido. Los gastos exactos de cada pedido dependen del país de recepción. Las tarifas de entrega varian en función del volumen del pedido y se calculan automáticamente antes de realizar el pago.</p>
                <div className="gastosEnvio">[[gastos-envio]]</div>
                <h4>6. Devoluciones</h4>
                <p>El cliente deberá ponerse en contacto previamente con el Servicio de Atención al Cliente de <span className="nombreEmpresa">[[nombre_empresa]]</span>. a través del e-mail <span className="correoElectronico">[[correo-electronico]]</span> y seguir las instrucciones de nuestro personal de atención al cliente.</p>
                <div className="devoluciones">[[devoluciones]]</div>
                <h4>7. Condiciones aplicables a los códigos promocionales</h4>
                <ul className="space-y-3 text-black list-disc marker:text-red-500 py-2 px-10">
                    <li>7.1. Cuando se utilizan códigos promocionales según sus términos y condiciones, se aplican a un solo descuento en la compra de un solo producto o a un descuento en un pedido en el período que indica el código promocional.</li>
                    <li>7.2. Los códigos promocionales son personales e intransferibles. Sólo se pueden utilizar para las compras realizadas en el Sitio web. El Cliente no puede vender, intercambiar ni asignar códigos promocionales. El código promocional no se puede cargar ni mostrar a través de sitios web ni otros medios para realizar ofertas públicas, regalos, ventas ni otros intercambios. Los códigos promocionales no se pueden utilizar con fines comerciales.</li>
                    <li>7.3. Sólo se puede utilizar un código promocional por pedido. Los códigos promocionales no se pueden combinar con ninguna otra oferta o promoción especial.</li>
                    <li>7.4. Los códigos promocionales no se pueden intercambiar por dinero.</li>
                    <li>7.5. <span className="nombreEmpresa">[[nombre_empresa]]</span> no tiene obligación alguna de volver a emitir ni hacer devoluciones de los códigos promocionales. Sin embargo, si <span className="nombreEmpresa">[[nombre_empresa]]</span> decide a su propia discreción volver a emitir o hacer una devolución de los códigos promocionales, sería necesario presentar el código promocional del Cliente. Por eso, el Cliente siempre debería guardar una copia del código promocional, sin el cual no se podría volver a emitir ni realizar una devolución.</li>
                    <li>7.6. <span className="nombreEmpresa">[[nombre_empresa]]</span> se reserva el derecho de anular un código promocional de forma inmediata si sospecha que se utiliza infringiendo alguna de las condiciones anteriores, se utiliza de cualquier forma incorrecta o si tiene razone de peso para anularlo.</li>
                </ul>
                <h4>8. Validez de las ofertas (Ofertas promocionales)</h4>
                <ul className="space-y-3 text-black list-disc marker:text-red-500 py-2 px-10">
                    <li>8.1. Las ofertas promocionales que se muestran en el Sitio web para incrementar la venta de ciertos productos sólo son válidas mientras aparecen en el Sitio web, a no ser que se especifique lo contrario en el mismo.</li>
                </ul>
                <h4>9. Responsabilidad</h4>
                <ul className="space-y-3 text-black list-disc marker:text-red-500 py-2 px-10">
                    <li>9.1. Estas Condiciones de venta establecen todas las obligaciones y responsabilidades de <span className="nombreEmpresa">[[nombre_empresa]]</span> respecto al suministro de productos. No existen garantías, condiciones ni otros términos que sean vinculantes para <span className="nombreEmpresa">[[nombre_empresa]]</span>, excepto las que se mencionan de forma expresa en las Condiciones de venta.</li>
                    <li>9.2. Sujeto a la cláusula 9.4, la responsabilidad en conjunto máxima de las partes entre sí en todas las reclamaciones de cualquier tipo o relacionadas con estas Condiciones de venta, ya sea en el contrato, garantía, condición, agravio, responsabilidad objetiva, ley o de otra forma, se limitará al importe pagado a <span className="nombreEmpresa">[[nombre_empresa]]</span> para el producto en cuestión.</li>
                    <li>9.3. Sujeto a la cláusula 9.4, en ningún caso, sea como consecuencia del incumplimiento del contraro, garantia, condición, agravio, responsabilidad objetiva, ley o de otra forma, ninguna de las partes será responsable frente a la otra de ningún daño especial, resultante, incidental o directo.</li>
                    <li>9.4. Nada de lo que figura en estas Condiciones de venta limita ni excluye la responsabilidad de <span className="nombreEmpresa">[[nombre_empresa]]</span> frente a:<br />
                        <ul>
                            <li>(i) la muerte o los daños personales causados por la negligencia de <span className="nombreEmpresa">[[nombre_empresa]]</span></li>
                            <li>(ii) el fraude</li>
                            <li>(iii) cualquier incumplimiento de las obligaciones que impliquen las leyes nacionales vigentes</li>
                            <li>(iv) de cualquier responsabilidad que no se pueda excluir por ley.</li>
                        </ul>
                    </li>
                </ul>
                <h4>Protección de los datos personales</h4>
                <p>Se informa a los Clientes que sus datos personales se incluirán en archivos propiedad de <span className="nombreEmpresa">[[nombre_empresa]]</span> para fines comerciales, publicidad de gestión relacionada con el cliente y prospección comercial, que incluye el envío (por correo, correo electrónico u otros medios electrónicos) de mensajes comerciales relacionados con los productos, servicios, ofertas, promociones y novedades relacionadas con la empresa <span className="nombreEmpresa">[[nombre_empresa]]</span>.
                </p>
                <p>Para fines directamente relacionados con el procesamiento de los datos personales, los datos personales del Cliente se pueden transmitir a la empresa <span className="nombreEmpresa">[[nombre_empresa]]</span>, que pueden estar ubicadas fuera del Espacio Económico Europeo (EEE), incluyendo países que no tienen un nivel comparable de protección de datos. <span className="nombreEmpresa">[[nombre_empresa]]</span>, y cualquier tercero a quién <span className="nombreEmpresa">[[nombre_empresa]]</span> transmita datos personales, tomarán y mantendrán medidas de seguridad técnicas y organizativas que sean adecuadas y suficientes para evitar el procesamiento de datos personales que
                    suponga el incumplimiento de: (a) estas Condiciones de venta o las leyes de protección de datos aplicables; y (b) cualquier pérdida, destrucción o daño accidentales de los datos personales.</p>
                <h4>Legislación aplicable</h4>
                <p>Las compras realizadas a través del Sitio web de <span className="nombreEmpresa">[[nombre_empresa]]</span> y bajo estas Condiciones de venta están sujetas a la legislación española.</p>
                <h4>Jurisdicción competente</h4>
                <p>Cualquier disputa o discrepancia que surja de la aplicación o interpretación de las Condiciones de venta, así como los contratos que incorpora, se resolverá en los tribunales de la ciudad donde esté habilitada la empresa. Ciudad que tendrá competencia exclusiva por encima de cualquier otra jurisdicción. Sin embargo, si el Cliente goza del estado de consumidor según la Ley española para la defensa de los consumidores y los usuarios, los tribunales competentes serán los del lugar de residencia del consumidor en España.</p>
            </div>
        </div>
    </>
    )
}

export default Condiciones