import React from 'react';
import 'assets/styles/App.css';
import HeaderComponent from 'components/header';
import BodyComponent from 'components/body';
import FooterComponent from 'components/footer';
import {BrowserRouter} from 'react-router-dom';

function App() {
return (
    <>
    <BrowserRouter>
        <HeaderComponent />
        <BodyComponent />
        <FooterComponent />
    </BrowserRouter>
    </>
);
}

export default App;
