import React from 'react';
import CopyToClipboard from 'components/button/CopyToClipboard';
import TextHandleChange from 'components/input/TextHandleChange';

const Legal = () => {
    return (
    <>
        <div className="flex_content"> 
            <div className="legaltext_content">
                <p className="component__title">Aviso legal y política de privacidad</p>
                <div className="component__description" role="alert">
                    <p className="font-bold">Por favor, introduzca los datos necesarios.</p>
                    <p className="text-sm">Aquello que añada en los campos de texto, se cambiará en el texto de abajo, para que pueda comprobar el resultado final.</p>
                </div>
                <div className="section__selectors">
                    <TextHandleChange inputValue={"nombreJuridico"} labelValue={"Nombre Jurídico"} />
                    <TextHandleChange inputValue={"nombreEmpresa"} labelValue={"Nombre Empresa"} />
                    <TextHandleChange inputValue={"denominacionSocial"} labelValue={"Denominación social"} />
                    <TextHandleChange inputValue={"NIF"} labelValue={"NIF"} />
                    <TextHandleChange inputValue={"direccionSocial"} labelValue={"Dirección social"} />
                    <TextHandleChange inputValue={"correoElectronico"} labelValue={"Correo electrónico"} />
                    <TextHandleChange inputValue={"telefono"} labelValue={"Teléfono"} />
                    <TextHandleChange inputValue={"paginaWeb"} labelValue={"Página web"} />
                </div>
                <CopyToClipboard/>
            </div>
            <div id="texttool-content">
                <h1>AVISO LEGAL</h1>
                <h2>DATOS DE LA EMPRESA</h2>
                <p>
                    En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico, a continuación se exponen los datos identificativos de la empresa.
                </p>
                <div className="group-list">
                    <p>
                        <strong>Nombre Jurídico: </strong><span  className="nombreJuridico">[[nombre_juridico]]</span>
                    </p>
                    <p>
                        <strong>Denominación social: </strong><span className="denominacionSocial">[[denominacion_social]]</span>
                    </p>
                    <p>
                        <strong>Número de identificación fiscal: </strong><span className="NIF">[[numero_nif]]</span>
                    </p>
                    <p>
                        <strong>Domicilio social: </strong><span className="direccionSocial">[[direccion_social]]</span>
                    </p>
                    <p>
                        <strong>Correo electrónico: </strong><span className="correoElectronico">[[correo_electronico]]</span>
                    </p>
                    <p>
                        <strong>Teléfono: </strong><span className="telefono">[[telefono]]</span>
                    </p>
                </div>

                <p>
                    El sitio web <span className="paginaWeb">[[pagina_web]]</span> (en adelante, el “Sitio Web”) es propiedad de <span className="nombreJuridico">[[nombre_juridico]]</span> con nombre comercial <span className="nombreEmpresa">[[nombre_empresa]]</span>.
                </p>

                <p>
                    El acceso al sitio Web <span className="paginaWeb">[[pagina_web]]</span> de <span className="nombreEmpresa">[[nombre_empresa]]</span> implica la aceptación de todas las
                    condiciones siguientes:
                </p>

                <h2>PROPIEDAD INDUSTRIAL</h2>

                <p>
                    El logotipo y la denominación “<span className="nombreEmpresa">[[nombre_empresa]]</span>”, así como otros signos distintivos
                    (gráficos o denominativos), que aparecen en esta Web son propiedad exclusiva de <span className="nombreEmpresa">[[nombre_empresa]]</span>,
                    de tal forma que es esta entidad la única autorizada a utilizarlos.
                </p>

                <p>Los contenidos de este sitio web, textos, imágenes, etc. así como su diseño gráfico y su código fuente están protegidos por la legislación española sobre los derechos de propiedad intelectual e industrial a favor de <span className="nombreEmpresa">[[nombre_empresa]]</span>.</p>

                <p>Quedando por tanto totalmente prohibida su reproducción, distribución o comunicación pública, total o parcial, sin la autorización expresa de <span className="nombreEmpresa">[[nombre_empresa]]</span>.</p>

                <h2>NOMBRE DE DOMINIO</h2>

                <p>De igual forma a lo reseñado en los párrafos anteriores, los nombres de dominio “<span className="paginaWeb">[[pagina_web]]</span>“ y todos aquellos que sirvan para acceder al presente sitio son de titularidad exclusiva de <span className="nombreEmpresa">[[nombre_empresa]]</span>. El uso indebido de los mismos supondría una infracción de los derechos adquiridos por su registro y será perseguido por los medios previstos en la Ley.</p>

                <h2>USO PERSONAL</h2>

                <p>El usuario queda expresamente autorizado por <span className="nombreEmpresa">[[nombre_empresa]]</span> a visualizar, imprimir, copiar o almacenar en su disco duro o cualquier otro soporte físico, los contenidos y activos amparados o no por un derecho de exclusiva (a excepción de los programas de ordenador), siempre que ésta actuación se efectúe para el uso personal y privado del usuario, sin finalidad comercial o de distribución y sin modificar, alterar o descompilar los antedichos derechos y contenidos. Esta licencia de uso personal se entiende efectuada siempre y cuando se respeten intactas las advertencias a los derechos de autor y de propiedad industrial que se realizan, sin que esto signifique la concesión de licencia alguna al usuario.</p>

                <p>Cualquier otro uso de los contenidos o creaciones intelectuales de este sitio requerirá la autorización expresa y por escrito de <span className="nombreEmpresa">[[nombre_empresa]]</span>.</p>

                <h2>RESERVA DE ACCIONES LEGALES</h2>

                <p><span className="nombreEmpresa">[[nombre_empresa]]</span> se reserva en todo momento el ejercicio de cuantos medios o acciones legales le correspondan en defensa de sus legítimos derechos de propiedad intelectual e industrial frente a un uso no autorizado de los mismos o a cualquier actuación que pudiera perjudicarlos.</p>

                <h2>ENLACES</h2>

                <p>Los enlaces con los que el usuario pueda encontrarse durante la navegación en este sitio Web le permiten abandonar este sitio.</p>

                <p>Los sitios enlazados no están relacionados, salvo indicación de lo contrario, con <span className="nombreEmpresa">[[nombre_empresa]]</span>, quien no se hace responsable de los contenidos a los que se acceda en virtud de los mencionados enlaces, ni de las modificaciones que se lleven a cabo en los mismos, ni del uso que de aquellos se realice, ni de la disponibilidad técnica de los mismos.</p>

                <p>En cualquier caso, <span className="nombreEmpresa">[[nombre_empresa]]</span> se compromete a hacer todo lo posible para evitar la existencia en su sitio Web de enlaces a páginas de contenido ilegal, que promuevan actividades ilícitas, racistas, xenófobas y, en general, susceptibles de atentar contra los principios de libertad y de dignidad humana o vulneren los valores y derechos reconocidos por la Constitución Española y por la Declaración Universal de los Derechos Humanos.</p>

                <p>En el supuesto de que algún usuario estimase que algún enlace incluido el Sitio Web menoscaba en cualquier forma sus derechos o si le supone el más mínimo perjuicio, tan sólo debe comunicarlo mediante el envío de un correo electrónico a <span className="nombreEmpresa">[[nombre_empresa]]</span>, teniendo la confianza de que se tomarán las medidas oportunas al respecto.</p>

                <h2>CONTENIDO DE LA WEB</h2>

                <p><span className="nombreEmpresa">[[nombre_empresa]]</span> no se responsabiliza del mal uso que se realice de los contenidos de nuestra página web, siendo exclusiva responsabilidad de la persona que accede a ellos o los utiliza.</p>

                <h2>ACTUALIZACIÓN Y MODIFICACIÓN DE LA PÁGINA WEB</h2>

                <p><span className="nombreEmpresa">[[nombre_empresa]]</span> se reserva el derecho a modificar o eliminar, sin previo aviso, tanto la información contenida en su sitio Web, así como su configuración y presentación, sin asumir responsabilidad alguna por ello.</p>

                <h2>INDICACIONES SOBRE ASPECTOS TÉCNICOS</h2>

                <p><span className="nombreEmpresa">[[nombre_empresa]]</span> no asume ninguna responsabilidad que se pueda derivar de problemas técnicos o fallos en los equipos informáticos que se produzcan durante la conexión a la red de Internet, así como de daños que pudieran ser causados por terceras personas mediante intromisiones ilegítimas fuera del control de <span className="nombreEmpresa">[[nombre_empresa]]</span>.</p>

                <p>También quedamos exonerados de toda responsabilidad ante posibles daños o perjuicios que pueda sufrir el usuario a consecuencia de errores, defectos u omisiones en la información que facilitemos cuando proceda de fuentes ajenas a nosotros.</p>

                <h2>CONDICIONES DE ACCESO</h2>

                <p>En general, el acceso a los contenidos facilitados por "<span className="nombreEmpresa">[[nombre_empresa]]</span>" será de carácter libre y gratuito, y no se exigirá a los usuarios que faciliten datos personales, ni se suscriban, ni hagan uso de claves o contraseñas, a excepción del acceso restringido a la sección de clientes de "<span className="nombreEmpresa">[[nombre_empresa]]</span>".</p>

                <p>Cuando para la contratación o solicitud de información de un determinado servicio sea necesario facilitar datos de carácter personal, los usuarios garantizarán su veracidad, exactitud, autenticidad y vigencia. "<span className="nombreEmpresa">[[nombre_empresa]]</span>" se compromete a dar a dichos datos el tratamiento automatizado que corresponda en función de la naturaleza y finalidad de los mismos, en las condiciones y términos que quedan reflejadas en la Política de Privacidad.</p>

                <p>Para aquellos casos en los que se hace necesario el uso de claves de acceso (nombre de usuario y contraseña), el usuario se compromete a usarlas de acuerdo a las siguientes restricciones:</p>

                <p>Las claves, elegidas por el usuario, no deberán ser contrarias a la moral y las buenas costumbres generalmente aceptadas, ni de cualquier modo infringir los derechos de propiedad industrial e intelectual y al honor y a la propia imagen de terceros.</p>

                <p>Las claves de acceso son para uso personal y exclusivo del usuario titular de las mismas, y su custodia y correcta utilización son responsabilidad del mismo. "<span className="nombreEmpresa">[[nombre_empresa]]</span>" no se hace responsable por ningún perjuicio en que el usuario o terceros puedan incurrir como consecuencia de que otras personas hagan uso de sus claves, con o sin consentimiento o conocimiento del usuario.</p>

                <p>No obstante, "<span className="nombreEmpresa">[[nombre_empresa]]</span>" se compromete a cumplir con el deber de secreto respecto a las claves registradas y utilizadas por los usuarios.</p>

                <h2>CONDICIONES DE USO</h2>

                <p>El uso del sitio Web de "<span className="nombreEmpresa">[[nombre_empresa]]</span>" deberá obedecer al contenido de estas Condiciones Generales, a cualesquiera disposiciones legales aplicables y a las exigencias de la moral y las buenas costumbres generalmente aceptadas, así como a las de orden público.</p>

                <p>Salvo previa y expresa autorización de "<span className="nombreEmpresa">[[nombre_empresa]]</span>", los usuarios únicamente podrán hacer uso de los contenidos y servicios ofrecidos en este sitio Web a modo personal, absteniéndose de realizar cualquier acto que, directa o indirectamente, suponga una explotación comercial de los mismos.</p>

                <p>En todo caso, los usuarios utilizarán los contenidos y servicios facilitados por "<span className="nombreEmpresa">[[nombre_empresa]]</span>" de conformidad con la legalidad vigente, y asumirán las responsabilidades que les correspondan por las conductas o actividades que, en cualquier forma, puedan resultar ilícitas o lesivas para derechos de terceros o que puedan perjudicar, impedir o limitar el uso de este sitio Web a otros usuarios.</p>

                <p>Los usuarios se comprometen a no transmitir o difundir a través de "<span className="nombreEmpresa">[[nombre_empresa]]</span>" mensajes, imágenes, fotografías, software, datos o contenidos que:</p>
                <ul>
                    <li><p>de cualquier forma contravengan o atenten contra derechos fundamentales reconocidos por el ordenamiento jurídico.</p></li>
                    <li><p>induzcan, inciten o promuevan actuaciones delictivas, denigratorias, difamatorias, infamantes, violentas, discriminatorias por razón de sexo, raza, religión o ideología, o, en general, sean contrarias a la ley, a la moral o al orden público.</p></li>
                    <li><p>sean falsos, ambiguos, inexactos, exagerados, obscenos o extemporáneos.</p></li>
                    <li><p>se encuentren protegidos por cualesquiera derechos de propiedad intelectual o industrial pertenecientes a terceros, salvo previa y suficiente autorización de su legítimo titular.</p></li>
                    <li><p>lesionen o puedan lesionar el derecho al honor, a la intimidad personal, a la propia imagen de terceras personas o infrinjan la normativa sobre el secreto o confidencialidad de las comunicaciones.</p></li>
                    <li><p>constituyan publicidad ilícita, engañosa o desleal.</p></li>
                    <li><p>contengan virus, caballos de Troya, gusanos, bombas de tiempo o cualquier otro material o programa que pueda dañar o alterar el funcionamiento de equipos o sistemas informáticos.</p></li>
                </ul>
                <p>"<span className="nombreEmpresa">[[nombre_empresa]]</span>" se reserva el derecho unilateral, en cualquier momento y sin previo aviso o justificación, de denegar el acceso a su sitio Web a aquellos usuarios que incumplan estas Condiciones Generales de Uso.</p>

                <h2>POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS (L.O.P.D.)</h2>
                <p><span className="nombreJuridico">[[nombre_juridico]]</span> pone en conocimiento de los usuarios de este sitio Web, que podrá crear un archivo automatizado con los datos personales que sean puestos en conocimiento del referido titular, como consecuencia de la utilización de la presente Web y a causa de envíos de correos electrónicos, o bien sobre aquellos recabados a través de nuestro formulario de contacto, en estricto cumplimiento con lo preceptuado por la legislación en materia de protección de datos.</p>

                <p>En cumplimiento de lo dispuesto en la Ley Orgánica 15/1999, de 13 de diciembre, de Protección de Datos de Carácter Personal, los usuarios podrán ejercitar, en cualquier momento, los derechos de acceso, rectificación, oposición y cancelación de sus datos, que hubiesen sido recopilados y archivados. El ejercicio de estos derechos deberá efectuarse mediante comunicación escrita dirigida a la sede social de <span className="nombreJuridico">[[nombre_juridico]]</span> o a la dirección de correo electrónico <span className="correoElectronico">[[correo_electronico]]</span>, indicando, en cualquiera de los casos, como referencia "Datos Personales" y el referido derecho que pretendan accionar, adjuntando copia de su Documento Nacional de Identidad.</p>

                <p>Los datos registrados podrían ser utilizados con la finalidad prestarle y/o ofrecerle nuestros servicios, de efectuar estadísticas, la remisión de publicidad - incluso por medios electrónicos -, la gestión de incidencias o la realización de estudios de mercado, entre otras actividades.</p>

                <p>En su caso, los datos de carácter personal facilitados por los usuarios podrán ser comunicados a un tercero sólo para el cumplimiento de los fines señalados anteriormente y previo consentimiento de los interesados, que se entenderá otorgado si nada manifiestan al contrario en el plazo de quince días desde que facilitaran sus datos. En cualquier caso, el señalado consentimiento podrá ser revocable en cualquier momento mediante comunicación escrita dirigida a la sede social del titular, indicando como referencia "Baja Datos Personales".</p>
            </div>
        </div>
    </>
    )
}

export default Legal