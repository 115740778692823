import React, {useState} from 'react';
import AsideMenu from 'components/aside/AsideMenu';

const MenuMobile = () => {
    const [isOpen, setIsOpen] = useState(false);
    const genericHamburgerLine = `h-1 w-12 my-1 rounded-full bg-black transition ease transform duration-300`;

    return (
        <>
            <button
                className="flex flex-col h-12 w-20 justify-center items-end group z-50"
                onClick={() => setIsOpen(!isOpen)}
            >
                <div
                    className={`${genericHamburgerLine} ${
                        isOpen
                            ? "rotate-45 translate-y-3 opacity-50 group-hover:opacity-100"
                            : "opacity-100 group-hover:opacity-100"
                    }`}
                />
                <div className={`${genericHamburgerLine} ${isOpen ? "opacity-0" : "opacity-100 group-hover:opacity-100"}`} />
                <div
                    className={`${genericHamburgerLine} ${
                        isOpen
                            ? "-rotate-45 -translate-y-3 opacity-50 group-hover:opacity-100"
                            : "opacity-100 group-hover:opacity-100"
                    }`}
                />
            </button>
            <aside>
                <AsideMenu opened={`${isOpen ? "" : "display-none"}`} asideState={isOpen}  />
            </aside>
        </>
    )
}

export default MenuMobile;