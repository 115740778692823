import React, {useState} from 'react';
import {checkOptions} from 'lib/generic';
import './styles/checkBoxHandleChange.css';

const CheckBoxHandleChange = (props) => {
    const [isChecked, setIsChecked] = useState(false);

    const cBHandleChange = (field) => {
        setIsChecked(field.target.checked);
        checkOptions(field.target.checked, field.target.dataset.value);
    };

    return (
        <>
            <div className="section__selectors--checkbox">
                <label>
                    <input
                        type="checkbox"
                        checked={isChecked}
                        value={props.inputValue}
                        className={props.checkBoxKey}
                        name={`${props.checkBoxKey}[]`}
                        onChange={cBHandleChange}
                        data-value={props.checkBoxProp}
                    />
                    {props.labelValue}
                </label>
            </div>
        </>
    )
}

export default CheckBoxHandleChange