import React, {useState} from 'react';
import {checkElements} from 'lib/generic';
import './styles/TextHandleChange.css';

const TextHandleChange = (props) => {
    const [input, setInput] = useState({
        input: ''
    });

    const txtHandleChange = (field) => {
        setInput(field.target.value);
        checkElements(field);
    };

    return (
        <>
            <div className="section__selectors--group">
                <label>{props.labelValue}</label>
                <input
                    type="text"
                    value={input.inputValue}
                    id={props.inputValue}
                    name={props.inputValue}
                    onChange={txtHandleChange}
                    className="section__selectors--input"
                />
            </div>
        </>
    )
}

export default TextHandleChange