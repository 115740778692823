export const checkElements = (field) => {
    const elementos = document.querySelectorAll('.' + field.target.id);
    for (const elemento of elementos) {
        elemento.innerHTML = field.target.value;
    }
};

export const checkOptions = (status, id) => {
    if (status === true) {
        document.getElementById(id).classList.remove('display-none');
    } else {
        document.getElementById(id).classList.add('display-none');
    }
};
