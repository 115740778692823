import React from 'react';

const FooterComponent = () => {
  return (
    <>
    <footer>
        <div>&copy; acceseo projects SL - Todos los derechos reservados</div>
    </footer>
    </>
)
}

export default FooterComponent;